import i18n from "../translations/i18n";
import {
  FALLBACK_LANGUAGE,
  SUPPORTED_LANGUAGES,
  TSupportedLanguages,
} from "../translations/i18n.interfaces";

export const getLanguage = (language?: string): TSupportedLanguages => {
  if (!language) {
    language = i18n.language ?? "en";
  }
  language = language.split("-")[0];
  if (SUPPORTED_LANGUAGES.indexOf(language as TSupportedLanguages) === -1) {
    language = FALLBACK_LANGUAGE;
  }
  return language as TSupportedLanguages;
};

export default getLanguage;
