import {
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  reauthenticateWithCredential,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  updateCurrentUser,
  updatePassword,
  User,
} from "firebase/auth";
import i18n from "../translations/i18n";
import getLanguage from "../utils/getLanguage";
import { auth } from "./firebase";
import logAction from "./logging";

auth.languageCode = getLanguage();

i18n.on("languageChanged", () => {
  auth.languageCode = getLanguage();
});

export const getValidatedUser = async (): Promise<User | null> => {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged(
      (user) => {
        unsubscribe();
        resolve(user);
      },
      reject // pass up any errors attaching the listener
    );
  });
};

export const registerWithEmailAndPassword = async (
  email: string,
  password: string
) => {
  const userCredential = await createUserWithEmailAndPassword(
    auth,
    email,
    password
  );
  const user = userCredential.user;
  logAction("register");
  return user;
};

export const loginWithEmailAndPassword = async (
  email: string,
  password: string
): Promise<User> => {
  const userCredential = await signInWithEmailAndPassword(
    auth,
    email,
    password
  );
  const user = userCredential.user;
  logAction("login");
  return user;
};

export const resetPassword = (email: string) => {
  return sendPasswordResetEmail(auth, email);
};

export const logout = async () => {
  return signOut(auth);
};

export const updateUser = async (user?: User | null): Promise<void> => {
  if (!user) {
    user = auth.currentUser;
  }
  if (!user) {
    return Promise.resolve();
  }
  return updateCurrentUser(auth, user);
};

export const updateUserPassword = async (
  currentPassword: string,
  newPassword: string,
  user?: User | null
): Promise<void> => {
  if (!user) {
    user = auth.currentUser;
  }
  if (!user) {
    return Promise.resolve();
  }
  const credential = EmailAuthProvider.credential(
    user.email as string,
    currentPassword
  );
  await reauthenticateWithCredential(user, credential);
  return updatePassword(user as User, newPassword);
};

export const sendVerification = (user?: User | null): Promise<void> => {
  if (!user) {
    user = auth.currentUser;
  }
  if (!user) {
    return Promise.resolve();
  }
  return sendEmailVerification(user);
};

export default auth;
