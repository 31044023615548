import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import admin_cs from "./admin_cs.json";
import admin_de from "./admin_de.json";
import admin_en from "./admin_en.json";
import admin_fi from "./admin_fi.json";
import admin_hu from "./admin_hu.json";
import admin_ro from "./admin_ro.json";
import admin_sv from "./admin_sv.json";
import components_cs from "./components_cs.json";
import components_de from "./components_de.json";
import components_en from "./components_en.json";
import components_fi from "./components_fi.json";
import components_hu from "./components_hu.json";
import components_ro from "./components_ro.json";
import components_sv from "./components_sv.json";
import default_cs from "./default_cs.json";
import default_de from "./default_de.json";
import default_en from "./default_en.json";
import default_fi from "./default_fi.json";
import default_hu from "./default_hu.json";
import default_ro from "./default_ro.json";
import default_sv from "./default_sv.json";
import { FALLBACK_LANGUAGE } from "./i18n.interfaces";
import productCard_cs from "./productCard_cs.json";
import productCard_de from "./productCard_de.json";
import productCard_en from "./productCard_en.json";
import productCard_fi from "./productCard_fi.json";
import productCard_hu from "./productCard_hu.json";
import productCard_ro from "./productCard_ro.json";
import productCard_sv from "./productCard_sv.json";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: FALLBACK_LANGUAGE,
    defaultNS: "default",
    resources: {
      en: {
        default: default_en,
        components: components_en,
        admin: admin_en,
        productCard: productCard_en,
      },
      fi: {
        default: default_fi,
        components: components_fi,
        admin: admin_fi,
        productCard: productCard_fi,
      },
      de: {
        default: default_de,
        components: components_de,
        admin: admin_de,
        productCard: productCard_de,
      },
      sv: {
        default: default_sv,
        components: components_sv,
        admin: admin_sv,
        productCard: productCard_sv,
      },
      cs: {
        default: default_cs,
        components: components_cs,
        admin: admin_cs,
        productCard: productCard_cs,
      },
      hu: {
        default: default_hu,
        components: components_hu,
        admin: admin_hu,
        productCard: productCard_hu,
      },
      ro: {
        default: default_ro,
        components: components_ro,
        admin: admin_ro,
        productCard: productCard_ro,
      },
    },
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
    detection: {
      lookupCookie: "rauliLng",
      lookupLocalStorage: "rauliLng",
      lookupSessionStorage: "rauliLng",
    },
  });

if (process.env.NODE_ENV === "development") {
  // Add changeLanguage method to window when testing, as calling it's a lot faster to change this way
  // instead of setting localStorage and reloading page
  // @ts-expect-error TS doesn't like setting random functions to window...
  window.changeLanguage = i18n.changeLanguage;
}

export default i18n;
