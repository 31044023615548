// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import {
  connectFirestoreEmulator,
  disableNetwork,
  enableNetwork,
  initializeFirestore,
  persistentLocalCache,
  persistentMultipleTabManager,
} from "firebase/firestore";
import { autorun } from "mobx";
import config from "../config/getConfig";
import commonStorage from "../storage/Common";

// Initialize Firebase
export const app = initializeApp(config.firebaseConfig);
export const auth = getAuth(app);
export const db = initializeFirestore(app, {
  localCache: persistentLocalCache({
    tabManager: persistentMultipleTabManager(),
  }),
});

if (config.useEmulators === true) {
  connectAuthEmulator(auth, "http://localhost:9099");
  connectFirestoreEmulator(db, "localhost", 8080);
}

autorun(() => {
  if (commonStorage.online) {
    enableNetwork(db);
  } else {
    disableNetwork(db);
  }
});

export default db;
