import { Alert, Link } from "@mui/material";
import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";
import commonStorage from "../storage/Common";

const UpdateBanner = observer(() => {
  const { t } = useTranslation();
  const { updateAvailable, setUpdateState } = commonStorage;

  let text = t("Update is available! Click to update now.");
  switch (updateAvailable) {
    case "update-in-progress":
      text = t("Update in progress!");
      break;
    case "update-approved":
      text = t("Update finished, reloading...");
      break;
    default:
      break;
  }

  if (updateAvailable === "no-update") {
    return null;
  }

  if (updateAvailable === "update-available") {
    return (
      <Link
        href="#"
        onClick={(event) => {
          event.preventDefault();
          setUpdateState("update-approved");
        }}
      >
        <Alert
          severity="warning"
          sx={{
            zIndex: 1000000,
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            justifyContent: "center",
          }}
        >
          {text}
        </Alert>
      </Link>
    );
  }

  return (
    <Alert
      severity="warning"
      sx={{
        zIndex: 1000000,
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        justifyContent: "center",
      }}
    >
      {text}
    </Alert>
  );
});

export default UpdateBanner;
