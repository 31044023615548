import { DateTime } from "luxon";
import { makeObservable, observable, runInAction } from "mobx";
import { listenForNotificationChanges } from "../firebase/notification";
import { INotification } from "../firebase/notification.interfaces";
import { INotificationStorage } from "./Notification.interfaces";

class NotificationStorage implements INotificationStorage {
  notifications: INotification[] = [];
  _unsubscribe: (() => void) | null = null;

  constructor() {
    makeObservable(this, {
      notifications: observable,
      _unsubscribe: observable,
    });

    window.onbeforeunload = () => {
      this.unsubscribe();
    };

    window.addEventListener("beforeunload", () => {
      this.unsubscribe();
    });
  }

  subscribe = () => {
    const unsubscribe = listenForNotificationChanges((snapshot) => {
      snapshot.docChanges().forEach((change) => {
        const data = change.doc.data();
        const notification = {
          ...data,
          id: change.doc.id,
          date: DateTime.fromISO(data.date),
        } as INotification;

        switch (change.type) {
          case "added":
            runInAction(() => {
              this.notifications.push(notification);
              this.notifications.sort((a, b) => (a.date < b.date ? -1 : 1));
            });
            break;
          case "modified":
            runInAction(() => {
              this.notifications = this.notifications.map((x) =>
                x.id === notification.id ? notification : x
              );
              this.notifications.sort((a, b) => (a.date < b.date ? -1 : 1));
            });
            break;
          case "removed":
            runInAction(() => {
              this.notifications = this.notifications.filter(
                (x) => x.id !== notification.id
              );
              this.notifications.sort((a, b) => (a.date < b.date ? -1 : 1));
            });
            break;
          default:
            break;
        }
      });
    });
    runInAction(() => {
      this._unsubscribe = unsubscribe;
    });
  };

  unsubscribe = () => {
    if (this._unsubscribe !== null) {
      this._unsubscribe();
    }
    runInAction(() => {
      this._unsubscribe = null;
    });
    this.clear();
  };

  clear = () => {
    runInAction(() => {
      this.notifications = [];
    });
  };
}

const notificationStorage = new NotificationStorage();
export default notificationStorage;
