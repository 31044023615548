import { FirebaseError } from "firebase/app";
import { arrayUnion, doc, setDoc, updateDoc } from "firebase/firestore";
import { DateTime } from "luxon";
import userStorage from "../storage/User";
import db, { auth } from "./firebase";
import { ILogItem, TLoggingAction } from "./logging.interfaces";

export const actionToString = (action: TLoggingAction): string => {
  switch (action) {
    case "systemload":
      return "System load";
    case "projectbasket":
      return "Project basket";
    case "pdf":
      return "PDF";
    case "load_project":
      return "Load project";
    case "load_flat_project":
      return "Load flatroof project";
    case "save_project":
      return "Save project";
    case "save_flat_project":
      return "Save flatroof project";
    case "remove_project":
      return "Remove project";
    case "remove_flat_project":
      return "Remove flatroof project";
    case "materialbank":
      return "Material Bank";
    case "offer_request":
      return "Offer request";
    case "api_project_components":
      return "API Project";
    case "api_calculate_dimensions":
      return "API Dimensions";
    case "api_fetch_components":
      return "API Components";
    case "api_fetch_media":
      return "API Media";
    case "webshop_order":
      return "Webshop Order";
    default:
      return action.charAt(0).toUpperCase() + action.slice(1);
  }
};

const logAction = async (action: TLoggingAction) => {
  const uid = auth.currentUser?.uid;
  if (!uid) {
    return;
  }
  const now = DateTime.utc();
  const toLog: ILogItem = {
    datetime: now.toISO() as string,
    uid: uid,
  };

  try {
    const distributor = userStorage.distributorInfo?.id;
    if (distributor) {
      toLog.distributor = distributor;
    }
  } catch {
    /* empty */
  }

  const docRef = doc(db, "loggingV2", now.toFormat("yyyy-MM"));
  updateDoc(docRef, {
    [action]: arrayUnion(toLog),
  }).catch((error: FirebaseError) => {
    if (error.code === "not-found") {
      setDoc(docRef, {
        [action]: [toLog],
      });
    }
  });
};

export default logAction;
