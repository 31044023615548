import React from "react";
import { Link } from "wouter";

import logo from "../assets/image/logo.png";

export interface IRauliLogo {
  withoutLink?: boolean;
  maxWidth?: string;
  callback?: () => void;
}

const RauliLogo = (props: IRauliLogo) => {
  const { withoutLink, maxWidth, callback } = props;
  const logoImg = (
    <img
      src={logo}
      className="App-logo"
      alt="logo"
      style={{
        cursor: withoutLink ? "unset" : "pointer",
        maxWidth: maxWidth ?? "100%",
      }}
    />
  );

  if (withoutLink) {
    return logoImg;
  }

  return (
    <Link to="/" onClick={() => callback && callback()}>
      {logoImg}
    </Link>
  );
};

export default RauliLogo;
