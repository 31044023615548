import { IConfiguration } from "./getConfig.interfaces";

const DevelopmentConfig: Omit<IConfiguration, "supportedBrackets"> = {
  environment: "development",
  requestQuote:
    "http://127.0.0.1:5001/raulibrackets/europe-west1/v1-requestQuote",
  systemLoads:
    "http://127.0.0.1:5001/raulibrackets/europe-west1/v1-systemLoads",
  estimateProduction:
    "http://127.0.0.1:5001/raulibrackets/europe-west1/v1-estimateProduction",
  webshopOrder:
    "http://127.0.0.1:5001/raulibrackets/europe-west1/v1-webshopOrder",
  appUrl: "https://rauli.turator.fi",
  mapsToken: "AIzaSyBp43urdvTA14pOo5nLBYuCXtTqGXFHC04",
  firebaseConfig: {
    apiKey: "AIzaSyA9hH1lDwCvRh9F01WYw_22s5x9XsIN98Q",
    authDomain: "raulibrackets.firebaseapp.com",
    projectId: "raulibrackets",
    storageBucket: "raulibrackets.appspot.com",
    messagingSenderId: "729247532560",
    appId: "1:729247532560:web:e00e437b8d32e14b2d2c95",
  },
  maximumSnowLoad: 5000,
  maximumWindLoad: 2000,
  panelDimensions: {
    width: {
      min: 900,
      max: 1400,
    },
    height: {
      min: 1600,
      max: 2400,
    },
  },
  flatroofLandscapeTilt: 13,
  flatroofPortraitTilt: 10,
  version: "development",
  tokenUrl: "http://127.0.0.1:5001/raulibrackets/europe-west1/v1-token",
  wall: {
    railWidth: 3000,
    railGapWidth: 100,
  },
  nova: { railSku: "32001", minimumRailLength: 500, maxBracketSpacing: 800 },
  useEmulators:
    typeof window !== "undefined" &&
    window.location &&
    ["127.0.0.1", "localhost"].includes(window.location.hostname)
      ? true
      : false,
};

if (!DevelopmentConfig.useEmulators) {
  DevelopmentConfig.requestQuote =
    "https://europe-west1-raulibrackets.cloudfunctions.net/v1-requestQuote";
  DevelopmentConfig.systemLoads =
    "https://europe-west1-raulibrackets.cloudfunctions.net/v1-systemLoads";
  DevelopmentConfig.tokenUrl =
    "https://europe-west1-raulibrackets.cloudfunctions.net/v1-token";
}

export default DevelopmentConfig;
