export type TSupportedLanguages =
  | "en"
  | "fi"
  | "sv"
  | "cs"
  | "de"
  | "hu"
  | "ro";
export const SUPPORTED_LANGUAGES = [
  "en",
  "fi",
  "sv",
  "cs",
  "de",
  "hu",
  "ro",
] as const;
export const FALLBACK_LANGUAGE = "en";
