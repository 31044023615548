import React from "react";
import { Redirect, useRoute } from "wouter";
import getMedia from "../api/media";

const StaticRedirect = () => {
  const [, params] = useRoute("/material/:media");
  if (!params) {
    return <Redirect to="/" />;
  }

  const { media } = params;
  if (!media) {
    return <Redirect to="/" />;
  }

  const { url } = getMedia().find((x) => x.staticUrl === media) ?? {};
  if (url) {
    window.location.replace(`${window.location.origin}${url}`);
  }
  return <Redirect to="/" />;
};

export default StaticRedirect;
