import config from "../config/getConfig";

// const PRODUCTION = config.environment === "production";

// class Logger {
//   private getLine = () => {
//     const stackLine = new Error().stack;
//     console.log(stackLine);
//     ("asd");
//   };
//   public debug = (message: unknown, ...args: unknown[]) => {
//     if (PRODUCTION) {
//       return;
//     }
//     console.debug(message, ...args);
//   };
//   public log = (message: unknown, ...args: unknown[]) => {
//     if (PRODUCTION) {
//       return;
//     }
//     console.log(message, ...args);
//   };
//   public info = (message: unknown, ...args: unknown[]) => {
//     if (PRODUCTION) {
//       return;
//     }
//     this.getLine();
//     console.info(message, ...args);
//   };
//   public warn = (message: unknown, ...args: unknown[]) => {
//     if (PRODUCTION) {
//       return;
//     }
//     console.warn(message, ...args);
//   };
//   public error = (message: unknown, ...args: unknown[]) => {
//     if (PRODUCTION) {
//       return;
//     }
//     console.error(message, ...args);
//   };
// }

import { Logger } from "tslog";

export const logger = new Logger({
  type: config.environment === "production" ? "hidden" : "pretty",
  hideLogPositionForProduction: config.environment === "production",
});
