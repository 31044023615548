import { TBracketTypeWithoutNull } from "../storage/Project.interfaces";
import DevelopmentConfig from "./development";
import { IConfiguration } from "./getConfig.interfaces";
import ProductionConfig from "./production";
import StagingConfig from "./staging";

export const SUPPORTED_BRACKET_TYPES: TBracketTypeWithoutNull[] = [
  "tile-strong",
  "tile-nordic",
  "tile-creaton",
  "tile-raspont-universal",
  "tile-raspont-clay",
  "tile-raspont-palema",
  "all-in-one-tile-shape",
  "all-in-one-standing-seam",
  "profile-trapezoidal",
  "profile-t35",
  "profile-t45",
  "black-decra",
  "all-in-one-bitumen",
  "wall-wood",
  "wall-concrete",
  "wall-sandwich",
  "wall-metal-sheet",
  "wall-other",
  "tile-nova-strong-bolt",
  "tile-nova-universal",
  "tile-nova-270",
  "tile-nova-340",
  "tile-nova-strong-bolt",
  "bitumen-nova-mounting-plate",
  "metal-nova-compact",
  "metal-nova-mini-rail",
  "metal-nova-strong-bolt",
];

const getConfig = (): IConfiguration => {
  if (process.env.REACT_APP_CONFIG === "development") {
    return { ...DevelopmentConfig, supportedBrackets: SUPPORTED_BRACKET_TYPES };
  }
  if (process.env.REACT_APP_CONFIG === "staging") {
    return { ...StagingConfig, supportedBrackets: SUPPORTED_BRACKET_TYPES };
  }
  return { ...ProductionConfig, supportedBrackets: SUPPORTED_BRACKET_TYPES };
};

const config: IConfiguration = getConfig();
export default config;
